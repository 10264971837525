import { Link } from "gatsby"
import React from "react"
import { Button } from "react-bootstrap"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const OfficeSpacePage = () => (
  <Layout>
    <SEO title="Office Space" description="" lang="en" meta={[]} />

    <section className="office-space-page">
      <div className="transparent-window">
        <h1 className="text-center mb-5">Ready to work?</h1>
        <h2 className="text-center">Find your office space.</h2>

        <div className="button-div mt-5">
          <Link to="/africa/fast-accelerator/office-apply">
            <Button variant="secondary" size="lg">
              Apply
            </Button>
          </Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default OfficeSpacePage
